import { alpha } from '@mui/material';
import HomeKWsIcon from '@repo-ui/icons/HomeKWsIcon';
import HomeSTtIcon from '@repo-ui/icons/HomeSTtIcon';
import HomeTTsIcon from '@repo-ui/icons/HomeTTsIcon';

export const AI_BUTTON_DATA = [
  {
    icon: <HomeTTsIcon />,
    title1: '음성합성',
    title2: '(TTS)',
    subtitle: 'Text to Speech',
    textColor: '#fff',
    bgcolor: alpha('#000', 0.25),
    action: 'tts',
  },
  {
    icon: <HomeSTtIcon />,
    title1: '음성인식',
    title2: '(STT)',
    subtitle: 'Speech to Text',
    textColor: '#fff',
    bgcolor: alpha('#000', 0.25),
    action: 'stt',
  },
  {
    icon: <HomeKWsIcon />,
    title1: '호출어',
    title2: '(KWS)',
    subtitle: 'Keyword Spotting',
    textColor: '#fff',
    bgcolor: alpha('#000', 0.25),
    action: 'kws',
  },
];
