import { Box } from '@mui/material';
import HomeTopIntro from './components/HomeTopIntro/HomeTopIntro';
import HomeAItrainIntro from './components/HomeAItrainIntro/HomeAItrainIntro';
import HomeAItechIntro from './components/HomeAItechIntro/HomeAItechIntro';
import HomeCodingIntro from './components/HomeCodingIntro/HomeCodingIntro';

export function HomeMain() {
  return (
    <Box
      className="HomeMain-root"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <HomeTopIntro />
      <HomeAItrainIntro />
      <HomeAItechIntro />
      <HomeCodingIntro />
    </Box>
  );
}
