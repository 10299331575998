import { Box, ButtonBase, darken, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';

interface Props {
  icon: React.ReactNode;
  title1: string;
  title2: string;
  subtitle: string;
  textColor: string;
  bgcolor: string;
  onClick: MouseEventHandler;
}

export default function AITrainButton(props: Props) {
  const { icon, title1, title2, subtitle, textColor, bgcolor, onClick } = props;
  return (
    <ButtonBase
      className="AITrainButton-root"
      component="div"
      sx={{
        position: 'relative',
        bgcolor,
        color: textColor,
        py: 2.5,
        px: 4,
        maxWidth: 400,
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'row', sm: 'column-reverse', md: 'row' },
        borderRadius: '15px',
        alignItems: 'center',
        justifyContent: 'space-between',

        '&:hover': {
          bgcolor: darken(bgcolor, 0.2),
          color: darken(textColor, 0.2),
        },
        // linear-gradient border
        '&::before': {
          content: "''",
          position: 'absolute',
          inset: 0,
          borderRadius: '15px',
          padding: '2px',
          background: 'linear-gradient(to right,#039BE5,#C862DA)',
          WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
        },
      }}
      onClick={onClick}
    >
      <Box sx={{ mr: { xs: 0, md: 2 }, mt: { xs: 0, sm: 2, md: 0 } }}>
        <Typography
          sx={{
            fontSize: {
              xs: '1rem',
              lg: '1.3rem',
            },
            fontWeight: 500,
            whiteSpace: 'nowrap',
            textAlign: { xs: 'left', sm: 'center', md: 'left' },
          }}
        >
          {title1}
          {title2}
        </Typography>

        <Typography
          sx={{
            fontSize: {
              xs: '0.8rem',
              md: '0.9rem',
            },
            mt: 0.5,
            whiteSpace: 'nowrap',
            textAlign: { xs: 'left', sm: 'center', md: 'left' },
          }}
        >
          {subtitle}
        </Typography>
      </Box>

      {/* icon */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 56,
          height: 56,
        }}
      >
        {icon}
      </Box>
    </ButtonBase>
  );
}
