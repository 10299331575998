import { Box, Container, Typography } from '@mui/material';
import { XsOrDown } from '@ocodelib/ui-common';
import HomeMainMenu from './HomeMainMenu';
import HomeSubMenu from './HomeSubMenu';

export default function HomeTopIntro() {
  return (
    <Box
      className="HomeTopIntor-root"
      sx={{
        width: '100%',
        overflow: 'hidden',
        mb: { xs: 8, lg: 10 },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          justifyContent: 'space-between',
        }}
      >
        <Box
          className="HomeTopIntro-textBox"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            mt: { xs: 8, lg: 10 },
            mb: { xs: 5, lg: 8 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              fontSize: { xs: '2.5rem', lg: '3rem' },
            }}
          >
            AI⬝코딩{' '}
            <XsOrDown>
              <br />
            </XsOrDown>
            교육 서비스
          </Typography>

          <Typography
            variant="h5"
            sx={{ mt: 3, fontSize: { xs: '1.1rem', lg: '1.3rem' }, textAlign: 'center' }}
          >
            교과목, 인공지능, AI학습, 블록코딩, 파이썬,
            <XsOrDown>
              <br />
            </XsOrDown>{' '}
            자바스크립트, P5.js 까지!
            <br />
            오코드와 함께해요.
          </Typography>
        </Box>
        {/* Home 메인 5개 메인 버튼 */}
        <HomeMainMenu sx={{ mt: 5 }} />

        {/* Home 서브 3개 메인 버튼 */}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <HomeSubMenu sx={{ mb: 3, mt: 6 }} />
        </Box>
      </Container>
    </Box>
  );
}
