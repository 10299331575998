import { alpha } from '@mui/material';

export const CODING_CARD_DATA = [
  {
    icon: '/_static/images/home_block.png',
    iconSize: {
      w: 98,
      h: 90,
    },
    title: '블록코딩',
    desc: '블록을 사용해 쉽게\n프로그래밍 개념을 배우자!',
    textColor: '#039be5',
    bgColor: '#313335',
    imgBgColor: `linear-gradient(to bottom, ${alpha('#039BE5', 0.15)} 0%, #313335 100%)`,
    href: '/project/blox',
  },
  {
    icon: '/_static/images/home_python.png',
    iconSize: {
      w: 86,
      h: 87,
    },
    title: '파이썬',
    desc: '간결하고 읽기 쉬운 문법을 가진\n고급 프로그래밍 언어',
    textColor: '#2775DB',
    bgColor: '#313335',
    imgBgColor: `linear-gradient(to bottom, ${alpha('#2775DB', 0.15)} 0%, #313335 100%)`,
    href: '/project/py',
  },
  {
    icon: '/_static/images/home_java.png',
    iconSize: {
      w: 104,
      h: 77,
    },
    title: '자바스크립트',
    desc: '웹 개발에 주로 사용되는\n프로그래밍 언어',
    textColor: '#fdb73d',
    bgColor: '#313335',
    imgBgColor: `linear-gradient(to bottom, ${alpha('#fdb73d', 0.15)} 0%, #313335 100%)`,
    href: '/project/js',
  },

  {
    icon: '/_static/images/home_p5.png',
    iconSize: {
      w: 82,
      h: 83,
    },
    title: 'P5.js',
    desc: '인터랙티브 그래픽을 위한\n자바스크립트 라이브러리',
    textColor: '#ef5350',
    bgColor: '#313335',
    imgBgColor: `linear-gradient(to bottom, ${alpha('#ef5350', 0.15)} 0%, #313335 100%)`,
    href: '/project/p5',
  },
];
