import { Box, ButtonBase, SxProps, Typography } from '@mui/material';
import { flatSx } from '@ocodelib/ui-common';
import HomeTopMenuFaqIcon from '@repo-ui/icons/HomeTopMenuFaqIcon';
import HomeTopMenuGalleryIcon from '@repo-ui/icons/HomeTopMenuGalleryIcon';
import HomeTopMenuMyCodingIcon from '@repo-ui/icons/HomeTopMenuMyCodingIcon';
import { routerPush } from '@repo-ui/router';
import clsx from 'clsx';

interface Props {
  sx?: SxProps;
  className?: string;
}

const HOME_BTN_DATA = [
  { icon: <HomeTopMenuGalleryIcon />, title: '갤러리', onclick: () => routerPush('/gallery/list') },
  {
    icon: <HomeTopMenuMyCodingIcon />,
    title: '나의 코딩',
    onclick: () => routerPush('/coding/mine'),
  },
  { icon: <HomeTopMenuFaqIcon />, title: 'FAQ', onclick: () => routerPush('/faq') },
];

export default function HomeSubMenu(props: Props) {
  const { sx, className } = props;
  return (
    <Box
      className={clsx('HomeSubMenu-root', className)}
      sx={flatSx(
        {
          width: { xs: '100%', md: 853 },
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        },
        sx,
      )}
    >
      {HOME_BTN_DATA.map((it) => (
        <ButtonBase
          component="div"
          key={it.title}
          onClick={it.onclick}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            justifyContent: { xs: 'flex-end', md: 'space-between' },
            alignItems: 'center',
            width: 267,
            height: '100%',
            mt: { xs: 1, md: 0 },
            py: 2,
            px: 4,
            background: '#f3f5f8',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="h6"
            fontWeight={500}
            textAlign="center"
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            {it.title}
          </Typography>
          {it.icon}
        </ButtonBase>
      ))}
    </Box>
  );
}
