import EastIcon from '@mui/icons-material/East';
import { Box, Button, Container, Grid2, Typography } from '@mui/material';
import { routerPush } from '@repo-ui/router';
import { useLocale } from 'next-intl';
import { CODING_CARD_DATA } from './coding-card-datas';
import CodingCard from './CodingCard';

export default function HomeCodingIntro() {
  const locale = useLocale();
  return (
    <Box
      className="HomeCodingIntro-root"
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        background: '#26282a',
        py: { xs: 8, md: 0 },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          minHeight: 700,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          className="HomeCodingIntro-titleBox"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            mb: 5,
            px: { xs: 1, md: 0 },
            maxWidth: { xs: 400, sm: '100%' },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '2.4rem', md: '2.6rem' },
              textAlign: { xs: 'center', sm: 'left' },
              color: '#fff',
            }}
          >
            코딩하기
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              fontWeight: 300,
              fontSize: { xs: '1.1rem', lg: '1.2rem' },
              mt: 1.5,
            }}
          >
            기초 블록코딩에서 고급 텍스트코딩, 그래픽까지 다양한 프로그래밍 언어 구현
          </Typography>
        </Box>

        <Box sx={{ width: '100%', maxWidth: { xs: 400, sm: '100%' } }}>
          <Grid2
            container
            columnSpacing={2.5}
            rowSpacing={3}
            sx={{ justifyContent: 'space-between', mt: 1 }}
          >
            {CODING_CARD_DATA.map((it) => (
              <Grid2 key={it.title} size={{ xs: 12, sm: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xs: 'center',
                      md: 'flex-start',
                    },
                  }}
                >
                  <CodingCard {...it} />
                </Box>
              </Grid2>
            ))}
          </Grid2>

          {/* 자세히 보기 버튼 */}
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
              color="inherit"
              variant="outlined"
              endIcon={<EastIcon />}
              sx={{
                mt: 8,
                border: '1px solid #76787A',
                color: '#76787A',
                borderRadius: '20px',
              }}
              onClick={() => {
                routerPush(locale, '/coding/list/block');
              }}
            >
              자세히 보기
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
