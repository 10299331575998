import { Box, Container, Grid2, Typography } from '@mui/material';
import { XsOrDown } from '@ocodelib/ui-common';
import NextImage from 'next/image';
import { toast } from 'react-toastify';
import { AI_BUTTON_DATA } from './ai-button-datas';
import AITrainButton from './AITrainButton';

export default function HomeAItrainIntro() {
  return (
    <Box
      className="HomeAItrainIntro-root"
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: { xs: 520, lg: 600 },
        overflow: 'hidden',
        background: '#000',
        py: { xs: 3, lg: 5 },
      }}
    >
      {/* background_img*/}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 0,
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        }}
      >
        <NextImage
          src="/_static/images/home_ai_bg.png"
          alt=""
          width={1919}
          height={638}
          priority={false}
          quality={50}
        />
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <Box
          className="HomeAItrainIntro-titleBox"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            mb: { xs: 5, lg: 9 },
          }}
        >
          <Typography
            sx={(theme) => ({
              height: 110,
              lineHeight: 1.3,
              fontSize: { xs: '2.5rem', md: '2.8rem' },
              color: '#fff',
              textAlign: { xs: 'center', md: 'left' },
              mt: 3,
              '& em': {
                color: theme.palette.primary.dark,
                fontStyle: 'normal',
              },
            })}
          >
            All-in-One,
            <XsOrDown>
              <br />
            </XsOrDown>{' '}
            <em>Ocode</em>
          </Typography>
        </Box>

        <Box
          className="HomeAItrainIntro-contentBox"
          sx={{
            pb: 5,
            px: { xs: 1, md: 0 },
            width: '100%',
            maxWidth: { xs: 400, sm: '100%' },
          }}
        >
          <Typography
            sx={{
              mt: { xs: 3, sm: 0 },
              fontSize: { xs: '2.4rem', lg: '2.6rem' },
              color: '#fff',
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            인공지능 교육
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              fontWeight: 300,
              fontSize: { xs: '1.1rem', lg: '1.2rem' },
              mt: 1.5,
            }}
          >
            음성기반 인공지능 기술을 활용한 음성합성, 음성인식, 호출어로 다양한 경험 제공
          </Typography>

          <Grid2
            container
            columnSpacing={2}
            rowSpacing={2}
            sx={{ mt: 5, justifyContent: 'space-between' }}
          >
            {AI_BUTTON_DATA.map((it) => (
              <Grid2 key={it.title1} size={{ xs: 12, sm: 4 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xs: 'center',
                      md: 'flex-start',
                    },
                  }}
                >
                  <AITrainButton
                    {...it}
                    onClick={() => {
                      toast.warn('준비중입니다.');
                    }}
                  />
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
}
