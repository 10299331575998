'use client';

import { Box, useMediaQuery } from '@mui/material';
import { HomeMain } from './HomeMain';

export function HomeFeature() {
  const isSmall = useMediaQuery(`(max-width:600px)`);

  return (
    <Box
      className="HomeFeature-root"
      sx={{
        position: 'relative',
      }}
    >
      {/* {isSmall ? "Small" : "Not Small"} */}
      {/* {isSmall ? <HomeMainMobile /> : <HomeMain />} */}
      <HomeMain />
    </Box>
  );
}
