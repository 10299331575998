export const AI_TECH_CARD_DATA = [
  {
    imgUrl: '/_static/images/home_card_ex1.png',
    title: '얼굴 아바타',
    action: 'face_avatar',
  },
  {
    imgUrl: '/_static/images/home_card_ex1.png',
    title: '사운드 이퀄라이저',
    action: 'sound',
  },
  {
    imgUrl: '/_static/images/home_card_ex1.png',
    title: '만화 얼굴 생성',
    action: 'cartoon_create',
  },
  {
    imgUrl: '/_static/images/home_card_ex1.png',
    title: '얼굴 랜드마크',
    action: 'face_land',
  },
  {
    imgUrl: '/_static/images/home_card_ex1.png',
    title: '손 랜드마크',
    action: 'hand_land',
  },
  {
    imgUrl: '/_static/images/home_card_ex1.png',
    title: '자세 랜드마크',
    action: 'pose_land',
  },
];
