import { Box, ButtonBase, Typography } from '@mui/material';
import NextImage from 'next/image';
import { routerPush } from '@repo-ui/router';

interface Props {
  icon: string;
  iconSize: { w: number; h: number };
  title: string;
  desc: string;
  textColor: string;
  bgColor: string;
  imgBgColor: string;
  href: string;
}

export default function CodingCard(props: Props) {
  const { icon, iconSize, title, desc, textColor, bgColor, imgBgColor, href } = props;
  return (
    <ButtonBase
      className="CodingCard-root"
      component="div"
      onClick={() => routerPush(href)}
      sx={{
        position: 'relative',
        width: '100%',
        maxWidth: { xs: 600, md: 410 },
        display: 'flex',
        flexDirection: { xs: 'row', md: 'column' },
        justifyContent: { xs: 'flex-start', md: 'space-between' },
        overflow: 'hidden',
        borderRadius: '10px',
        background: bgColor,
      }}
    >
      <Box
        sx={{
          width: { xs: 160, sm: 200, md: '100%' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: imgBgColor,
          py: 5,
        }}
      >
        <Box
          className="CodingCard-imgBox"
          sx={{
            width: 104,
            height: 90,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NextImage src={icon} alt="" width={iconSize.w} height={iconSize.h} priority={false} />
        </Box>
      </Box>

      <Box
        className="CodingCard-textBox"
        sx={{
          mb: 3,
        }}
      >
        <Typography
          sx={{
            color: textColor,
            overflow: 'hidden',
            textAlign: { xs: 'start', md: 'center' },
            fontSize: '1.5rem',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
            mx: 2,
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            color: '#fff',
            overflow: 'hidden',
            textAlign: { xs: 'start', md: 'center' },
            fontSize: { xs: '0.78rem', lg: '0.8rem' },
            textOverflow: 'ellipsis',
            fontWeight: 300,
            whiteSpace: 'pre-line',
            mt: { xs: 1, md: 2 },
            mx: 2,
          }}
        >
          {desc}
        </Typography>
      </Box>
    </ButtonBase>
  );
}
